import React, { useState, useEffect, useRef } from 'react';
import './index.scss'
import { get } from '../../util/request';
import { useTranslation } from 'react-i18next';
import { userInfo } from '../../redux/slice';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { post } from '../../util/request';
import {
  i18n
} from 'i18next';
import Loading from '../../components/common/loading';
import { set } from 'lodash';
import SubmitSuccessPopUp from '../../components/page/submitSuccessPopUp';
import { useHistory } from 'react-router';
import { REGEX } from '../../constants';


const TaxQuestionnaire = () => {
  const { t } = useTranslation();
  const user = useSelector(userInfo);
  const [content, setContent] = React.useState('');
  const [model, setModel] = useState({})
  const [isTaxFillingAvailable, setIsTaxFillingAvailable] = useState(false)
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();

  const containerRef = useRef();

  function goDetail(url) {
    history.push({
      pathname: url
    });
  }

  let params = {
    accessToken: user.accessToken,
    userId: user.userId,
  }
  const getQuestionnaire = async () => {
    await get('content/taxQuestionnaire', params).then((data) => {
      setModel(data[0])
      getContent(data[0]);
    })
  }

  const checkIfEligibleForTaxFillingQuestionnaire = async () => {
    await get('content/isTaxFillingQuestionnaireAvailable', params).then((data) => {
      if (data) {
        setIsTaxFillingAvailable(data);
      }
      else {
        goDetail('/home');
      }
    })
  }

  const handleOk = () => {
    setIsModalOpen(false);
    if (!isError) {
      goDetail('/home');
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.type !== "textarea") {
      event.preventDefault();
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    const postalRegex = /^[a-zA-Z0-9\s-]*$/;
    const addressZipcode = document.getElementsByName('AddressZipcode')[0];
    if (addressZipcode != null) {
      if (postalRegex.test(addressZipcode.value)) {
        addressZipcode.setCustomValidity('');
      }
      else {
        addressZipcode.setCustomValidity(t('Incorrect Postal'));
        isValid = false;
      }
    }

    const foreignZipcode = document.getElementsByName('ForeignZipcode')[0];
    if (foreignZipcode != null) {
      if (postalRegex.test(foreignZipcode.value)) {
        foreignZipcode.setCustomValidity('');
      }
      else {
        foreignZipcode.setCustomValidity(t('Incorrect Postal'));
        isValid = false;
      }
    }

    if (isValid) {
      const formData = new FormData(event.target);
      const formProps = Object.fromEntries(formData);

      formProps['accessToken'] = user.accessToken;
      formProps['userId'] = user.userId;
      setLoading(true);
      await post('/content/taxQuestionnaire', formProps).then((data) => {
        if (data.message === 'Success') {
          setIsModalOpen(true);
          setLoading(false);
          setIsError(false);
        }
      }).catch(() => {
        setIsModalOpen(false);
        setLoading(false);
        setIsError(true);
      });
    }
  };


  useEffect(() => {
    checkIfEligibleForTaxFillingQuestionnaire();
  }, []);

  useEffect(() => {
    if (isTaxFillingAvailable) {
      getQuestionnaire();
    }
  }, [isTaxFillingAvailable])

  useEffect(() => {
    if (content !== '') {
      setLoading(false);

      const changedVisaType = document.getElementsByName('ChangedVisaType')[0];
      if (changedVisaType != null) {
        changedVisaType.addEventListener("change", (e) => {
          const changedVisaTypeReason = document.getElementsByName('ChangedVisaTypeReason')[0];
          if (changedVisaTypeReason != null) {
            if (e.target.value === 'Yes') {
              changedVisaTypeReason.removeAttribute('disabled');
            }
            else {
              changedVisaTypeReason.setAttribute('disabled', true);
              changedVisaTypeReason.value = '';
            }
          }
        });
      }

      const visitedUSPast3Years = document.getElementsByName('VisitedUSPast3Years')[0];
      if (visitedUSPast3Years != null) {
        visitedUSPast3Years.addEventListener("change", (e) => {
          const periodStayedInUSTwoYearsAgo = document.getElementsByName('PeriodStayedInUSTwoYearsAgo')[0];
          if (periodStayedInUSTwoYearsAgo != null) {
            if (e.target.value === 'Yes') {
              periodStayedInUSTwoYearsAgo.removeAttribute('disabled');
            }
            else {
              periodStayedInUSTwoYearsAgo.setAttribute('disabled', true);
              periodStayedInUSTwoYearsAgo.value = '';
            }
          }

          const periodStayedInUSLastYear = document.getElementsByName('PeriodStayedInUSLastYear')[0];
          if (periodStayedInUSLastYear != null) {
            if (e.target.value === 'Yes') {
              periodStayedInUSLastYear.removeAttribute('disabled');
            }
            else {
              periodStayedInUSLastYear.setAttribute('disabled', true);
              periodStayedInUSLastYear.value = '';
            }
          }

          const periodStayedInUSCurrentYear = document.getElementsByName('PeriodStayedInUSCurrentYear')[0];
          if (periodStayedInUSCurrentYear != null) {
            if (e.target.value === 'Yes') {
              periodStayedInUSCurrentYear.removeAttribute('disabled');
            }
            else {
              periodStayedInUSCurrentYear.setAttribute('disabled', true);
              periodStayedInUSCurrentYear.value = '';
            }
          }
        });
      }

      const filedUSTaxBefore = document.getElementsByName('FiledUSTaxBefore')[0];
      if (filedUSTaxBefore != null) {
        filedUSTaxBefore.addEventListener("change", (e) => {
          const filedUSTaxForms = document.getElementsByName('FiledUSTaxForms')[0];
          if (filedUSTaxForms != null) {
            if (e.target.value === 'Yes') {
              filedUSTaxForms.removeAttribute('disabled');
            }
            else {
              filedUSTaxForms.setAttribute('disabled', true);
              filedUSTaxForms.value = '';
            }
          }
        });
      }

      const usBankAccountRoutingNumber = document.getElementsByName('USBankAccountRoutingNumber')[0];
      if (usBankAccountRoutingNumber != null) {
        usBankAccountRoutingNumber.addEventListener("input", (e) => {
          if (e.target.value.length > 9) {
            e.target.value = e.target.value.slice(0, 9);
          }
        });
      }

      const usBankAccountNumber = document.getElementsByName('USBankAccountNumber')[0];
      if (usBankAccountNumber != null) {
        usBankAccountNumber.addEventListener("input", (e) => {
          if (e.target.value.length > 12) {
            e.target.value = e.target.value.slice(0, 12);
          }
        });
      }

      const postalRegex = /^[a-zA-Z0-9\s-]*$/;
      const addressZipcode = document.getElementsByName('AddressZipcode')[0];
      if (addressZipcode != null) {
        addressZipcode.addEventListener("input", (e) => {
          if (postalRegex.test(e.target.value)) {
            addressZipcode.setCustomValidity('');

          }
          else {
            addressZipcode.setCustomValidity(t('Incorrect Postal'));
            e.preventDefault();
          }
        });
      }

      const foreignZipcode = document.getElementsByName('ForeignZipcode')[0];
      if (foreignZipcode != null) {
        foreignZipcode.addEventListener("input", (e) => {
          if (postalRegex.test(e.target.value)) {
            foreignZipcode.setCustomValidity('');
          }
          else {
            foreignZipcode.setCustomValidity(t('Incorrect Postal'));
            e.preventDefault();
          }
        });
      }

      const foreignCountryName = document.getElementsByName('ForeignCountryName')[0];
      if (foreignCountryName != null) {
        foreignCountryName.addEventListener("change", (e) => {
          const foreignProvince = document.getElementsByName('ForeignProvince')[0];
          foreignProvince.options.length = 0;
          getStateOption(foreignProvince, e.target.value);
        });
      }
    }
  }, [content])

  const getStateOption = async (foreignProvince, country) => {
    let param = {
      accessToken: user.accessToken,
      userId: user.userId,
      country: country
    }
    const data = await get('/content/getStates', param);
    if (data) {
      for (var i = 0; i < data.length; i++) {
        var opt = document.createElement('option');
        opt.value = data[i];
        opt.innerHTML = data[i];
        foreignProvince.appendChild(opt);
      }
    }
  };

  React.useEffect(() => {
    if (Object.keys(model).length > 0) {
      getContent(model);
    }
  }, [localStorage['lang']]);

  const getContent = (model) => {
    switch (localStorage['lang']) {
      case 'zh_HK':
        return setContent(model.contentSC)
      case 'zh_CN':
        return setContent(model.contentTC)
      case 'jp':
        return setContent(model.contentJA)
      default:
        return setContent(model.contentEN)
    }
  }

  return (
    <div className='tax-questionnaire' id='tax-questionnaire-id' ref={containerRef} >
      {loading ? <Loading /> : null}
      {
        content !== '' ? (<>
          <SubmitSuccessPopUp isModalOpen={isModalOpen} title={isError ? t('SUBMISSION ERROR') : t('submissionSuccessful')} content={isError ? t('Submission error. Please try again.') : t('submissionSuccessful')} handleOk={handleOk} handleCancel={handleOk} icon={isError ? 'submit-error' : 'submit-success'} />
          <form id='tax-
          form' onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {isTaxFillingAvailable ? <input type='submit' value={t('Submit')} className='submit-button' /> : null}
          </form>
        </>) : null
      }
    </div>
  );
};
export default TaxQuestionnaire;
